//import { v4 } from "uuid"
import { isEmpty } from "lodash"
import DOMPurify from "dompurify"

/**
 * Extracts and returns float value from a string.
 *
 * @param {string} string String
 * @return {any}
 */
export const getFloatVal = string => {
  let floatValue = string.match(/[+-]?\d+(\.\d+)?/g)[0]
  return null !== floatValue
    ? parseFloat(parseFloat(floatValue).toFixed(2))
    : ""
}

/* export const isUserLoggedIn = () => {
  let authData = null

  if (process.browser) {
    authData = JSON.parse(localStorage.getItem("auth"))
  }
  return authData
} */

export const logOut = () => {
  localStorage.removeItem("auth")
}

export const setAuth = authData => {
  localStorage.setItem("auth", JSON.stringify(authData))
}

/**
 * Check if user is logged in.
 *
 * @return {object} Auth Object containing token and user data, false on failure.
 */
export const isUserValidated = () => {
  let userLoggedInData = ""

  if (process.browser) {
    let authTokenData = localStorage.getItem("auth")

    if (!isEmpty(authTokenData)) {
      authTokenData = JSON.parse(authTokenData)

      if (!isEmpty(authTokenData.authToken)) {
        userLoggedInData = authTokenData
      }
    }
  }

  return userLoggedInData
}

/**
 * Sanitize markup or text when used inside dangerouslysetInnerHTML
 *
 * @param {string} content Plain or html string.
 *
 * @return {string} Sanitized string
 */
export const sanitize = content => {
  return typeof window !== "undefined" ? DOMPurify.sanitize(content) : content
}
