const React = require("react")
const Layout = require("./src/components/layout/layout").default

export { wrapRootElement } from "./src/apollo/wrap-root-element"

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === "undefined") {
    await import("intersection-observer")
  }
}
