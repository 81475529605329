import React, { useState, useEffect } from "react"
import { isEmpty } from "lodash"

export const AppContext = React.createContext([{}, () => {}])

export const AppProvider = props => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false)
  const [userName, setUserName] = useState(null)

  useEffect(() => {
    if (process.browser) {
      let authData = JSON.parse(localStorage.getItem("auth"))
      if (!isEmpty(authData)) {
        //console.log(authData)
        setIsUserLoggedIn(true)
        setUserName(authData.user.username)
      }
    }
  }, [isUserLoggedIn])

  return (
    <AppContext.Provider value={[isUserLoggedIn, setIsUserLoggedIn, userName]}>
      {props.children}
    </AppContext.Provider>
  )
}
