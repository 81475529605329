import React, { useState, useContext } from "react"
import Img from "gatsby-image"
import { Link, StaticQuery, graphql } from "gatsby"
import { AppContext } from "../context/AppContext"
import { logOut } from "../../utils/functions"
import useSticky from "../hook/useSticky"
import SocialIcons from "../social-links"
import "./index.scss"

const Header = () => {
  const [isUserLoggedIn, setIsUserLoggedIn, userName] = useContext(AppContext)
  const [openMenu, setOpenMenu] = useState(false)
  const { isSticky, element } = useSticky()

  const handleLogout = () => {
    logOut()
    setIsUserLoggedIn(false)
    setOpenMenu(!openMenu)
  }

  const handleToggleMenu = () => {
    setOpenMenu(!openMenu)
  }

  return (
    <StaticQuery
      query={graphql`
        query HeaderQuery {
          file(relativePath: { eq: "chatdecks-logo.png" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          allWpMediaItem(filter: { title: { eq: "card-decks" } }) {
            nodes {
              sourceUrl
            }
          }
        }
      `}
      render={data => {
        return (
          <div
            ref={element}
            className={`header-bg ${isSticky ? "header-sticky" : ""}`}
          >
            <button
              onClick={handleToggleMenu}
              className={`hamburger ${openMenu ? "open" : ""}`}
            >
              <div></div>
            </button>
            <div className={`header-menu-wrapper container`}>
              <div className="header-menu__logo">
                <Link to="/">
                  {data.allWpMediaItem.nodes.lenght ? (
                    <img
                      src={data.allWpMediaItem.nodes[0].sourceUrl}
                      alt="logo"
                    />
                  ) : (
                    <Img
                      fluid={data.file.childImageSharp.fluid}
                      alt="chatdecks logo"
                    />
                  )}
                </Link>
              </div>
              <nav className={`header-menu ${openMenu ? "open" : ""}`}>
                <ul className="header-menu__list">
                  <Link onClick={handleToggleMenu} to="/">
                    <li className="header-menu__list-item">Home</li>
                  </Link>
                  <Link onClick={handleToggleMenu} to="#">
                    <li className="header-menu__list-item">About Us</li>
                  </Link>
                  <Link onClick={handleToggleMenu} to="/#contact">
                    <li className="header-menu__list-item">Contact</li>
                  </Link>
                  {isUserLoggedIn ? (
                    <div className="user-info">
                      <Link onClick={handleToggleMenu} to="/app/welcome">
                        <li className="header-menu__list-item">Welcome</li>
                      </Link>
                      <span className="user-info__name">{`${userName}`}</span>
                      <button
                        className="user-info__logout"
                        onClick={handleLogout}
                      >
                        Logout
                      </button>
                    </div>
                  ) : (
                    <a onClick={handleToggleMenu} href="https://chatdecks.simplyeffectiveweb.com/app/wp-login.php">
                      <li className="header-menu__list-item">Sign In</li>
                    </a>
                  )}
                </ul>
                <SocialIcons styles="icons-header" />
              </nav>
            </div>
          </div>
        )
      }}
    />
  )
}

export default Header
