import React from "react"
import PropTypes from "prop-types"
import Header from "../header/index"
import { createMuiTheme } from "@material-ui/core/styles"
import { ThemeProvider } from "@material-ui/styles"
import "./layout.scss"

const theme = createMuiTheme({
  typography: {
    fontFamily: `Overpass, sans-serif;`,
    // fontSize: 14,
    fontWeightLight: 500,
    fontWeightRegular: 600,
    fontWeightMedium: 700,
  },
  palette: {
    primary: {
      main: "#00abc8",
      // light: "#757ce8",
      // dark: "#002884",
      // contrastText: "#fff",
    },
    secondary: {
      main: "rgba(0, 95, 114, 1)",
      // light: "#ff7961",
      // dark: "#ba000d",
      // contrastText: "#000",
    },
  },
})

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <main className="main">{children}</main>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
