import React from "react"
import LinkedInIcon from "@material-ui/icons/LinkedIn"
import TwitterIcon from "@material-ui/icons/Twitter"
import FacebookIcon from "@material-ui/icons/Facebook"
// import FacebookIcon from "../icons/facebook-icon"
// import TwitterIcon from "../icons/twitter-icon"
// import YouTubeIcon from "../icons/youtube-icon"
import "./index.scss"

const SocialIcons = ({ styles }) => {
  return (
    <ul className={`${styles ? styles : "icons"}`}>
      <li>
        <a className="icons-local" href="https://www.facebook.com/chatdecks">
          <FacebookIcon fontSize="inherit" />
        </a>
      </li>
      <li>
        <a className="icons-local" href="https://twitter.com/chatdecks">
          <TwitterIcon fontSize="inherit" />
        </a>
      </li>
      <li>
        <a href="https://www.linkedin.com/in/darren-sweet-a652ab163/">
          <LinkedInIcon fontSize="inherit" />
        </a>
      </li>
    </ul>
  )
}

export default SocialIcons
